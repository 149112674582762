import React, { useEffect, useState } from 'react';
import BasePage from '../../components/BasePage';
import QrcodeImg from './qrcode.png';
import { IsPC } from '../../util';

const ContactUs = (props) => {
  const isPC = IsPC()

  return (
    <BasePage currentPath={props.match.path} >
      <div style={{ marginTop: isPC ? '' : '150px', width: isPC ? '' : window.innerWidth }}>
        <div className="center">
          <img src={QrcodeImg} style={{ width: isPC ? 800 : window.innerWidth - 20, border: '1px solid #90caf9' }} />
        </div>

      </div>
    </BasePage>

  )
}


export default ContactUs;