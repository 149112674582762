import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import bgImg from '../../images/home-bg.png'
import firstImg from '../../images/home-p1.png'

import Rename from '../../images/reName.png';
import MessImg from '../../images/message.png';
import Face from '../../images/face.png';
import PersonPosition from '../../images/person.png';
import Splinter from '../../images/pt.png';
import Err from '../../images/err.png';
import Computer from '../../images/computer.png';
import Base from '../../images/base.png';
import ImportImg from '../../images/import.png';
import FaceWall from '../../images/faceWall.png';
import Ps from '../../images/psd.png';
import Cloud from '../../images/cloud.png';
import Code from '../../images/code.png';
import Percent from '../../images/percent.png';
import One from '../../images/111.png';
import Two from '../../images/222.png';
import Three from '../../images/333.png';
import Four from '../../images/444.png';
import Five from '../../images/555.png';
import Six from '../../images/666.png';
import Kf from '../../images/kf.png'
import ToTop from '../../images/totop.png'
import KfCode from '../../images/kfcode.png'
import { useHistory } from 'react-router-dom';
import { IsPC } from '../../util'
import './style.css';
const Home = (props) => {
 const [showCode,setCode]=useState(false) 
  const history = useHistory()
  const isPC = IsPC()
  useEffect(() => {
    const ele = document.getElementById('header-bar');
    const concat = document.getElementById('concat');
    const scrollHandler = (e) => {
      const scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
      // console.log('get scrollTop',scrollTop)
      if (scrollTop > 50) { 
        ele.classList.add('scrollTop')
        concat.style.display='block'
      } else {
        ele.classList.remove("scrollTop")
        concat.style.display='none'
      }
    }

    window.addEventListener('scroll', scrollHandler)

    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }

  }, [])
  console.log("pc ", isPC)


  const features = [
    {
      title: '多方原因造成个性化相册寸步难行',
      points: [
        { icon: Splinter, title: '复杂相框拼接麻烦', desc: '使用PS完成排版，在增加多边形、星星、爱心等特殊形状相框时，需要耗费大量时间进行拼凑裁切' },
        { icon: Err, title: '客户信息匹配错误', desc: '通讯录页和创意照片墙需要多次拖拽粘贴信息才可生成，而且经常容易出现学生与信息不对应的情况' },
        { icon: PersonPosition, title: '人像位置反复调整', desc: '普通排版工具容易出现人像不匹配相框、裁剪掉了头部脸部等问题，经常需要后期手动调整位置' },
        { icon: Face, title: '入册照片千篇一律', desc: '由于筛选识别成本大，每本相册只能入册相同照片，导致毕业册出现没有或只有一张包含某个学生的照片，客户满意度低、客诉多、复购率差' },
        { icon: MessImg, title: '定稿环节流程混乱', desc: '排版完成后，往往需要先导出样片让客户进行反馈，若客户不满意，还需要反复修改并导出，最终导致定稿时间延长，效率不高' },
        { icon: Rename, title: '手动命名所有照片', desc: '拍摄照片后需手动将入册学生的照片分单独文件夹整理，自命名与肉眼筛选不仅容易漏片，有时甚至难以匹配到对应学生，耗时耗力' },

        // "支持导入X10及N8的模板文件"
      ],
      text: [
        '做起来困难',
        '排版时间久',
        '人工成本高'
      ],
    },
    // {
    //   text: ['为解决以上问题，真正实现3分钟排版100本单人个性化相册', 'D9携硬核功能强势来袭']
    // },

    {
      title: "AI人脸识别智能排版",
      desc: '源于强大的AI人脸识别技术，D9构建了一套完备的智能排版方案，包括AI人脸分片、精细化调整人脸位置、筛选特定人数照片入册、动态生成通讯录等，实现了从低成本批量出片到高精度个性定制的功能集成',
      points: [
        { icon: One, text: " AI人像自动分片", desc: ['根根据人脸识别结果', '自动筛选包含该人物的照片入册'] },
        { icon: Two, text: "精细化调整人脸位置", desc: ['进行模板预设后', '系统批量调整人像到相框中的特定区域'] },
        { icon: Three, text: "筛选特定人数照片", desc: ['算法筛选特定人数照片入册', '更好把控相册照片的搭配'] },
        
        { icon: Four, text: "自动关联表单数据", desc: ['人像与其对应信息一一绑定', '快速匹配至相框中，批量生成通讯录'] },
        { icon: Five, text: "随时填写补充信息", desc: ['支持生成在线报名链接', '客户可随时补充信息至相册排版中'] },
        { icon: Six, text: "实时反馈-定稿系统", desc: ['支持发起在线定稿链接', '客户反馈意见可实时查阅'] },

      ],
    },
    // {
    //   desc: "AI人脸识别技术：排版提速20倍的秘诀",
    //   points: [

    //     { icon: Four, text: "自动关联表单数据", desc: ['人像与其对应信息一一绑定', '快速匹配至相框中，批量生成通讯录'] },
    //     { icon: Five, text: "随时填写补充信息", desc: ['支持生成在线报名链接', '客户可随时补充信息至相册排版中'] },
    //     { icon: Six, text: "实时反馈-定稿系统", desc: ['支持发起在线定稿链接', '客户反馈意见可实时查阅'] },

    //   ],

    // },
    {
      title: "日常基础排版功能",
      points: [
        { icon: Computer, title: '0基础快速上手' },
        { icon: Base, title: '丰富的模板库' },
        { icon: ImportImg, title: '批量导入文件夹' },
        { icon: FaceWall, title: '一键制作人脸墙' },
        { icon: Ps, title: '支持导入PSD' },
        { icon: Cloud, title: '资料收集系统' },
        { icon: Code, title: '在线扫码定稿' },
        { icon: Percent, title: '在线管理团队进度' },
      ],
    },
  ]
  const [showMenu, setMenu] = useState(false)
  const menus = [
    { name: "首页", path: '/' },
    { name: "下载", path: '/download.html' },
    { name: "联系我们", path: '/contact.html' },
    { name: "帮助教程", path: '/help.html' }
  ]

  return (
    <div className="f16">
      {!isPC&&<div style={{height:10,background:'#000004'}}></div>}
      <div className="video-bg">
        <img src={bgImg} alt="" className="bg-img" />
        <div className="banner1-info new">
          {/* <div className='line-circle'>
            <div className='left'>
              <div className='line'></div>
              <div className='circle'></div>
            </div>
            <div className='text center'>
              <img src={Logo} style={{ width: isPC ? 50 : 35 }} mode="widthFix"></img>
            </div>
            <div className='right' style={{ width: isPC ? '' : '82%' }}>
              <div className='circle'></div>
              <div className='line'></div>
            </div>
          </div> */}
          <div className="wording show">
            <div className="move-logo center"></div>
            <div className="move-title center" style={{ fontSize: isPC ? '' : '14px' }}>个性化纪念册的一站式AI智能解决方案</div>
            {/* <div className="move-title" style={{ color: '#fff', fontWeight: 'normal' }}>智能个性影相</div> */}
            <div className="move-desc center" style={{ fontSize: isPC ? '' : '1rem', marginTop:isPC?'':window.innerWidth<375?'-6px':'10px', marginBottom:isPC?'':'1.2rem'}}>
              <div className='center' style={{height:isPC?'3rem':'16px'}}>专注毕业场景 · AI人脸识别技术 · 智能分片 · AI人脸排版、AI人脸云端选片、卖片等</div>
              <div className='center' style={{marginTop:window.innerWidth<375?7:''}}>为您高效能+低成本打造最具竞争力的个性化毕业季产品</div>

            </div>
            {/* {isPC ? <div className="move-down">
              <a href="/download.html" className="move-down-a a1" style={{ color: 'black' }}>
                <span>立即下载</span>
                <span>立即下载</span>
              </a>
            </div> :
              <div> 
              </div>
            } */}
            {isPC ? <div className="move-down center" >
              <a target="_blank" href="https://mp.weixin.qq.com/s?__biz=MzU5ODMyNjYxNw==&mid=2247484488&idx=1&sn=b5eec862a9846a3ce73b1568d5aa8488&chksm=fe44a87ec9332168e2d26af718efc05cdcee3fd9fb00aed18605f92d5672e9de9bf9bb209e4f#rd" className="move-down-a"
                style={{ color: '#fff', backgroundColor: '#000409', border: '0.2rem solid #fff' }} >
                <span>快速上手</span>
                <span>快速上手</span>
              </a>
            </div> :
              <div className="btnMobile center">
                <a target="_blank" href="https://mp.weixin.qq.com/s?__biz=MzU5ODMyNjYxNw==&mid=2247484488&idx=1&sn=b5eec862a9846a3ce73b1568d5aa8488&chksm=fe44a87ec9332168e2d26af718efc05cdcee3fd9fb00aed18605f92d5672e9de9bf9bb209e4f#rd" className="move-down-a"
                  style={{ color: '#fff' }}
                >快速上手</a>
              </div>
            }
          </div>
          {/* <div className="first-img i-box" style={{ display: !isPC ? 'block' : '' }}  > */}
          <img src={firstImg} alt="" className="first-img jc" style={{ width: isPC ? '45%' : '30%', top: isPC ? '' : '65%', right: isPC ? '' : '1%' }} />
          {/* </div> */}
        </div>
      </div>
      <Header currentPath={props.match.path} isPC={isPC}
        onMenuClick={() => {
          let show = !showMenu
          setMenu(show)
        }}
      />
      <div>

      </div>
      {features.map((item, key) => {
        // const pointNode = item.points.map((point, subkey) => (
        //   <div className="point-desc" key={subkey} >
        //     {point}
        //   </div>
        // ))
        return (
          <div className="intro-box" key={key} style={{}}>
            <div className="intro-content h-inner" style={{ width:'100%' }}>
              {key === 0 ? <div style={{marginTop:'3.75rem'}}>
                <div className='center'>
                  {item.text.map((tItem, tIdx) => <span key={tIdx} className='f40 ml20' style={{fontSize:isPC?'':'2.3rem',margin:isPC?'':'0 5px'}}>{tItem}</span>)}
                </div>
                <div className='mt45 center'>{item.title}</div>
                <div className="flex jc_spcb auto" style={{width:isPC?'66%':'90%',marginTop:'4rem'}}>
                  {item.points.map((pItem, pIdx) => <div key={pIdx} style={{ width: isPC ? '15%' : '33.3%', marginBottom: '3.5rem' }} className='center'>
                    <img src={pItem.icon} style={{ width: '55%' }} mode='widthFix'></img>
                    <div className='f16 mt10 fw6'>{pItem.title}</div>
                    <div className='f16'>
                      {/* <div className="auto" style={{ width: '90%' }}>{pItem.desc}</div> */}
                    </div>
                  </div>)}
                </div>
              </div> : key === 1  ? <div className={isPC?'mt60':'mt30'} style={{ fontSize: 'calc(0.3rem + 12px)' }}>
                {key === 1 && <div className='f40 center mt30' style={{background: '#F8F9FA',height:'70px',lineHeight:'70px'}}>{item.title}</div>}
                {/* {key === 1 && <div className='mt20'>{item.desc}</div>} */}
                <div className="flex jc_spcb auto"  style={{width:isPC?'66%':'90%'}}>
                  {item.points.map((pItem, pIdx) => <div key={pIdx} style={{ width: isPC ? '28%' : '100%',background:'#F8F9FA',padding:'20px 16px',marginTop:'30px',borderRadius:'10px' }} className='center'>
                    <div style={{minHeight: 'calc(170px + 10rem)'}} className="flex alc jc_c">
                    <img src={pItem.icon} style={{ width:'278px' }} mode='widthFix'></img>

                    </div>
                    <div className='f26' style={{margin:'6px 0 16px 0'}}>{pItem.text}</div>
                    <div >
                      {pItem.desc.map((dItem, dIdx) => <div key={dIdx} className='center fwl'  style={{margin:'6px'}}>
                        {dItem}
                      </div>)}
                    </div>
                    {/* <button className='auto' style={{ width: 110, height: 30, marginTop: 10, backgroundColor: '#ffffff' }} onClick={() => {
                      history.push('/help.html')
                    }}>查看教程<span className='ml10'>{`>`}</span></button> */}
                  </div>)}

                </div>

              </div> : <div className='mt60'>
                <div className='f40 center mt30' style={{background: '#F8F9FA',height:'70px',lineHeight:'70px'}}>{item.title}</div>
                <div className="flex jc_spcb auto"  style={{width:isPC?'66%':'90%'}}>
                  {item.points.map((pItem, pIdx) => <div key={pIdx} style={{ width: isPC ? '22%' : '48%' }} className='center mt30'>
                    <div className='center' style={{ height: '9rem' }}>
                      <img src={pItem.icon} style={{ width: '50%' }} mode='widthFix'></img>
                    </div>
                    <div className='f26 mt10'>{pItem.title}</div>
                  </div>)}
                </div>
              </div>}

            </div>
            {/* <div className="intro-content h-inner" style={{ width: !isPC ? '100%' : '', margin: key % 2 === 0 ? '' : isPC?'11rem auto' :'',background:key % 2 === 0 ? '' : !isPC?'#f8f9ff' :'',}}>
              <div className='intro-header' style={{ marginTop: key === 0 ? '6.5rem' : '' }}>
                <div className='line-circle' style={{ width:isPC? '5%':'10%', position: 'relative' }}>
                  <div className='left' style={{ width: '100%' }}>
                    <div className='line'></div>
                    <div className='circle' style={{ width: '0.75rem', height: '0.75rem' }}></div>
                  </div>
                </div>
                <div className="intro-title">{item.title}</div>
              </div>
              {(key === 0 || key === 4 || key === 5) && <div style={{ position: 'absolute', bottom: 0, right: "10%", display: 'flex', justifyContent: 'center', flexWrap: 'wrap', width: '0.75rem' }}>
                <div style={{ width: '0.75rem', height: '0.75rem', borderRadius: '50%', backgroundColor: '#17f2f4' }}></div>
                <div style={{ width: '1px', height: '3rem', backgroundColor: '#3CB1BE' }}></div>
              </div>}
              {key === 0 && <div style={{ marginBottom: '2rem' }}>
                <div className="intro-title" style={{ paddingLeft:isPC? '5%':'10%' }}>实体摄影店等群体皆可使用</div>
                <div style={{ display: 'flex', flexWrap: 'wrap',marginLeft:'3rem' }}>
                  {item.points.map((item, index) => (
                    <div style={{ width: '45%', margin: '2rem 0rem 1rem 0' }}>
                      <img src={item.icon} alt="" style={{ width: '9.7rem', height: '7.5rem', verticalAlign: 'top' }} />
                      <div style={{ display: 'inline-block', width: isPC?'12rem':'15rem', marginLeft: '0.935rem' }}>
                        <div style={{ fontSize: '1.35rem', fontWeight: '600', marginBottom: '1rem' }}>{item.title}</div>
                        <div style={{ color: '#7b7b7d' }}>{item.desc}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              }
              {key === 1 && <div style={{ margin: '3rem auto', display: 'flex', flexWrap: !isPC ? 'wrap' : '',justifyContent: !isPC ? 'center' : '' }}>
                <div>
                  {item.points.map((item, index) => (
                    <div style={{ display: 'flex', alignItems: 'center', margin: isPC?'2rem 0 1rem 4.5rem':'0 3rem' }}>
                      <text style={{ fontWeight: '700', color: index % 2 === 0 ? '#3CB1BE' : 'black', fontSize: '2.87rem', transform: 'scaleY(1.2)', marginRight: '1rem' }}>0{index + 1}</text>
                      <div style={{ fontWeight: '600', fontSize: '1.85rem', whiteSpace: 'pre-wrap', width: '28rem' }} className="lt-spc2">{item.text}</div>
                    </div>
                  ))}
                </div> 
              </div>}

              {(key === 2 || key === 3) && <div style={{ marginBottom: key === 2 ? '2rem' : '', display: 'flex', flexWrap: !isPC ? 'wrap' : '' ,justifyContent: !isPC ? 'center' : '' }} >
              {!isPC&&<div>
                  {item.points.map((item, index) => (
                    <div style={{ display: 'flex', alignItems: 'center' ,marginTop:'1rem',width:'39rem'}}>
                      <text style={{ fontWeight: '700', color: index % 2 === 0 ? '#3CB1BE' : 'black', fontSize: '2.87rem', transform: 'scaleY(1.2)', marginRight: '1rem' }}>0{index + 1}</text>
                      <div>
                        <div style={{ fontWeight: '600', fontSize: '1.85rem', marginBottom: '0.35rem' }} className="lt-spc2">{item.text}</div>
                        <div style={{ color: '#7b7b7d'}} className="f1_4r lt-spc2">{item.desc}</div>
                      </div>
                    </div>
                  ))}
                </div>}
                {key === 2 ? <div>  

                </div> : <div></div>}
                {isPC&&<div style={{ height: key === 2 ? '30rem' : '25rem', display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                  {item.points.map((item, index) => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <text style={{ fontWeight: '700', color: index % 2 === 0 ? '#3CB1BE' : 'black', fontSize: '2.87rem', transform: 'scaleY(1.2)', marginRight: '1rem' }}>0{index + 1}</text>
                      <div>
                        <div style={{ fontWeight: '600', fontSize: '1.85rem', whiteSpace: 'pre-wrap', width: '23rem', marginBottom: '0.35rem' }} className="lt-spc2">{item.text}</div>
                        <div style={{ color: '#7b7b7d', whiteSpace: 'pre-wrap', width: '25rem' }} className="f1_4r lt-spc2">{item.desc}</div>
                      </div>
                    </div>
                  ))}
                </div>}

              </div>}

              {(key === 4 || key === 5) && <div style={{ marginBottom: key === 4 ? '4rem' : '', display: 'flex', flexWrap: 'wrap'}}>
                {key === 4 ? <div style={{ display: 'flex' }}>
                  
                </div> : <div style={{ display: 'flex' }}>
                   
                </div>}
                <div>
                  {item.points.map((item, index) => (
                    <div style={{ marginLeft: isPC?'5rem':'2rem' }}>
                      <h1 className='lt-spc2'>{item.text}</h1>
                      <div style={{ color: '#7b7b7d', marginBottom: isPC?'5rem':'3rem' }} className="f1_4r lt-spc2">{item.desc}</div>
                    </div>
                  ))}
                </div>

              </div>}
            </div> */}

          </div>
        )
      })}
      {/* <div style={{ width: '100%', height: '5rem', backgroung: '#fff' }}></div> */}
      <div>
        <div style={{ position: 'fixed', right: '9rem', bottom: '3rem', zIndex: 999 }} id="concat">
          {showCode && <div style={{ position: 'fixed', right: '1rem', bottom: '18rem', zIndex: 999  }} >
            <img src={KfCode} style={{width:210,borderRadius:'6px'}} mode="widthFix"></img>
            {/* <div>
              <img src={require('../../images/kf_a.png')} style={{ width: "2rem", height: 'auto', marginRight: '0.5rem' }} preview={false}></img>
              <div className={['in_b',isPc?'f1r':'f1_4r'].join(' ')} style={{ marginRight: '1.5rem' }}>微信客服</div>
            </div>
            <img src={require('../../images/qywx.png')} style={{ width: isPc?"8rem":'10rem', height: 'auto' }} preview={false}></img>
            <div className={['c_grey','tac' ,isPc?'f0_75r':'f1_4r'].join(' ')}>扫码添加企业微信客服</div> */}
          </div>}
          <div style={{ position: 'fixed', right: '5rem', bottom: '8.5rem' }} >
            <div className='flex alc jc_c' style={{ width: '4rem', height: '4rem', borderRadius: '50%',background:'black' }} onClick={() => {

              setCode(!showCode)}}>
              <img src={Kf} style={{ width: '2rem', height: '2rem' }} ></img>
            </div>
            <div className='flex  alc jc_c' style={{ width: '4rem', height: '4rem', borderRadius: '50%', marginTop: '0.5rem',background:'black'  }} onClick={() => {
              window.scrollTo(0, 0)
            }}>
              <img src={ToTop} style={{ width: '2rem', height: '2rem' }} ></img>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}


export default Home;