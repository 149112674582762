import React from 'react';


const Download = ()=>{
  return (
    <div className="">

      Download page
    </div>
  )
}


export default Download;