import React, { useState } from 'react';
import { IsPC } from '../../util';
import Logo from '../../images/logo.png'

import './style.css'
import copy from "copy-to-clipboard";


const Header = (props) => {
  console.log('get match', window.innerWidth)
  const menus = [
    { name: "首页", path: '/' },
    { name: "下载", path: '/download.html',includes: ['/downloadOk.html','/download.html'] },
    { name: "联系我们", path: '/contact.html' },
    // { name: "帮助教程", path: '/help.html' }
  ]
  const isPC = IsPC()


  const [showMenu, setShowMenu] = useState(false)

  const [toast,setToast]=useState(false)



  return (
    <div id="header-bar" className={`header ${props.isTop ? 'scrollTop' : ''}`} style={{position:isPC?'fixed':'absolute'}}>
      <span className="logo" style={{left:isPC?'':'10px'}}>
          <a href="/" alt="home">
          <div className='flex alc'>
          <img src={Logo} style={{ width: '25px' }} mode="widthFix"></img>
          <span className='c1 ml20' >D9-AI分片智能排版系统</span>
          </div>
          </a>
        </span>
        {/* <h1></h1> */}
      {isPC ? <div style={{position:'relative'}}>
        <div className="h-inner">
        
        <div className="nav">
          <ul className="nav-ul">
            {menus.map((item, key) => (
              <li key={key} className={`nav-item`} ><a href={item.path} className={`${(item.includes? item.includes.includes(props.currentPath): (item.path === props.currentPath)) ? 'active' : ''}`} alt="download">{item.name}</a></li>
            ))}
          </ul>
        </div>
      </div>
      <div  className='down flex alc jc_c'>
      <a href="/download.html" className="move-down-a a1 flex alc jc_c" style={{color:'#000424'}}>立即下载</a>
      </div>
      {/* <div className="move-down" style={{position:'absolute',right:'10rem',transform:'translateX(0)'}}>
      <a href="/download.html" className="move-down-a a1" style={{ color: 'black' }}>
        <span>立即下载</span>
        <span>立即下载</span>
      </a>
    </div> */}
      </div> :
        <div>
          <div>
            {/* <h1 className="logo" >
              <img src={'/logo_100.png'} alt="logo" onClick={() => {
                let show = !showMenu
                setShowMenu(show)
              }} />
            </h1> */}
          </div>
<div style={{position: 'absolute',right: '12rem', top:'15px'}}>
<a href="/contact.html" className="move-down-a a1 flex alc jc_c" style={{color:'#ffffff'}}>联系我们</a>

  </div>         
   <div  className='down flex alc jc_c' style={{right:isPC?'':'1.5rem',top:isPC?'':'13px'}}>

      <a className="move-down-a a1 flex alc jc_c" style={{color:'#000424'}} onClick={()=>{setShowMenu(true)}}>立即下载</a>
      </div>
          {/* {showMenu &&  */}
          {/* <div className="menuDiv" style={{ width: 100 }}>

            {menus.map((item, key) => (
              <div key={key} className="memuLi " ><a href={item.path} className={`${item.path === props.currentPath ? 'active c1' : ' c1 '}`} alt="download">{item.name}</a></div>
            ))}

          </div> */}
          {toast&&<div className="toast">复制成功</div>}
          {showMenu &&<div style={{width:window.innerWidth,height:'100vh',position:'absolute',left:0,top:0,background:'rgba(0,0,0,0.2)'}}>
            <div style={{width:'90%',height:'200px',position:'absolute',left:'50%',top:'50%',transform:'translate(-50%,-50%)',background:'#fff',borderRadius:'6px'}}>
               <div className='center f26 pt20' style={{borderBottom:'1px solid #cccccc'}}>
                下载D9
               </div>
               <div className='pt20 center'>
                  请在电脑上打开此网址进行软件下载
               </div>
               <div className='pt20 center'>
                  <span style={{color:'#b7b7b7'}}>https://d9.aiphoto8.cn/download.html</span>
                  <span style={{color:'#FFBB05'}} className="ml10" onClick={()=>{
                    copy('https://d9.aiphoto8.cn/download.html')
                    setToast(true)
  setTimeout(() => {
    setToast(false)
  }, 2000);
                  }}>点击复制</span>
               </div>
               <div className='center'>
                <button style={{background:'#000004',border:'none',color:'#ffffff',borderRadius:'6px',padding:'7px 20px',marginTop:'20px'}} 
                onClick={()=>{setShowMenu(false)}}>我知道了</button>
               </div>
            </div>

          </div>}



        </div>}
    </div>
  )
}


export default Header;