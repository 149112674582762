import React, { useEffect, useState } from 'react';
import BasePage from '../../components/BasePage';
import bgImg from '../../images/helpBg.png'

import { IsPC } from '../../util';

const Help = (props) => {
  const isPC = IsPC()
  const videos = [
    { url: "https://cdn.hui51.cn/videos/1668647591654.mp4", title: 'ceshii' },
    { url: "https://cdn.hui51.cn/videos/1668647591654.mp4", title: 'ceshii' },
    { url: "https://cdn.hui51.cn/videos/1668647591654.mp4", title: 'ceshii' },
    { url: "https://cdn.hui51.cn/videos/1668647591654.mp4", title: 'ceshii' },
    { url: "https://cdn.hui51.cn/videos/1668647591654.mp4", title: 'ceshii' },
    { url: "https://cdn.hui51.cn/videos/1668647591654.mp4", title: 'ceshiidgaehtrjtyekuyrliuto;yip非的沙滩和认同和' },
    { url: "https://cdn.hui51.cn/videos/1668647591654.mp4", title: 'ceshii' },


  ]
  return (
    <BasePage currentPath={props.match.path} >
      <div>
        {isPC ? <div className="video-bg">
          <img src={bgImg} alt="" className="bg-img" />
          <div style={{ position: 'absolute', top: '1rem', left: '50%', transform: "translateX(-50%)" }}>
            <div className='center' style={{ color: '#fff', fontSize: '2.5rem' }}>帮助教程</div>
            <div style={{ color: '#fff' }} className="flex mt20">
              <div style={{ width: '5px', height: '30px', background: '#2176FA' }} className="mr10"></div>
              <div style={{ fontSize: '1.2rem' }}>视频教程</div>
            </div>
            <div style={{ width: '80vw', height: '55vh', background: '#fff', borderRadius: '10px', padding: '10px' }} className="flex jsc_spcb">
              {videos.map((item, index) => <div style={{ margin: '1%', width: '18%' }}>
                <video src={item.url} controls="controls" style={{ width: '100%' }} height={180} className="vam"
                />
                <div className='center hideWord mt10' style={{ width: '100%' }}>{item.title}</div>
              </div>)}
            </div>
          </div>
        </div> : <div  >
          <div className='center' style={{ fontSize: '2rem', backgroundColor: '#000424', color: '#ffffff', padding: 10 }}>帮助教程</div>
          <div style={{backgroundColor:'#f5f5f5',padding:10}}>
            {videos.map((item, index) => <div style={{ margin: '0 auto', width: '90%' }}>
              <video src={item.url} controls="controls" style={{ width: '100%' }} height={180} className="vam"
              />
              <div className='center hideWord mt10' style={{ width: '100%',fontSize:'14px' }}>{item.title}</div>
            </div>)}
          </div>
        </div>}
      </div>
    </BasePage>

  )
}


export default Help;