// API 对接
export const getClientUrl = () => {
  return new Promise((resolve, reject) => {
    fetch('//face.hui51.cn/api/d9/client_latest_url', {
      method: 'GET',
      headers: { 'Accept': 'application/json' },
    }).then((res) => res.json()).then((json) => {
      resolve(json.data);
    }).catch((err) => {
      reject(err)
    })
  })
}