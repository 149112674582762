import React, { useEffect, useState } from 'react';
import BasePage from '../../components/BasePage';


const AboutUs = (props) => {


  return (
    <BasePage currentPath={props.match.path} >
      <div className="h-inner">
        <div className="">
          更新中...
        </div>

      </div>
    </BasePage>

  )
}


export default AboutUs;