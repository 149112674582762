
export const IsPC = () => {
  var userAgentInfo = navigator.userAgent;
  console.log(navigator, userAgentInfo.indexOf('iPhone') > 0)
  var Agents = ["Android", "iPhone",
    "SymbianOS", "Windows Phone",
    "iPad", "iPod"];
  var flag = true;
  for (var v = 0; v < Agents.length; v++) {
    // console.log(userAgentInfo.indexOf(Agents[v]) > 0, Agents[v])
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
}


export const getQueryByLocation = (location) => {
  let query = {}
  // console.log('get location', location.hash)
  if (location.search) {
    let pairs = location.search.split('?')[1]
    if (pairs) {
      pairs = pairs.split('&')
      let len = pairs.length;
      for (let i = 0; i < len; i++) {
        let keyVals = pairs[i].split('=')
        query[keyVals[0]] = decodeURIComponent(keyVals[1]);
      }
    }

  }
  // console.log('get query', query)
  return query;
}

export function debounce(
  fn,
  wait, /** 等待时间 */
  immediate /** 是否立刻执行一次 */
) {
  const now = Date.now.bind(Date);
  let lastTime = 0;
  let timer = null;
  let params = null;
  let _this = null;

  function later() {
    const nowTime = now();

    if (nowTime - lastTime < wait) {
      // 如果还不够等待时间则继续等待
      const remainTime = wait - (nowTime - lastTime);

      timer = setTimeout(later, remainTime);
    } else {
      // 已到等待时间，执行回调
      debouncer.result = fn.apply(_this, params);

      timer = null;
      _this = null;
      params = null;
    }
  }

  function execute() {
    lastTime = now();
    _this = this;
    params = arguments;

    try {
      if (immediate && timer === null) {
        // 立刻执行一次
        debouncer.result = fn.apply(_this, params);
      }

      return debouncer.result;
    } finally {
      if (timer === null) {
        // 加入时间队列，等待执行
        timer = setTimeout(later, wait);
      }
    }
  }

  // 创建执行器
  const debouncer = {
    execute,
    result: null,
  };

  return debouncer;
};