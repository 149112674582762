import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { IsPC } from '../../util';
const isPC=IsPC()
const BasePage  = (props)=>(

  <div className="">
      {isPC&&<Header currentPath={props.currentPath} isTop={true}  />}
      <div className="d-content">
        {props.children}
      </div>
      <Footer fixed={props.hideFixed?false:true} currentPath={props.currentPath}/>
  </div>
)


export default BasePage;