import React from 'react';
import BasePage from '../../components/BasePage';
import { IsPC } from '../../util';

const Term = (props) => {

const isPC=IsPC()
  return (
    <BasePage currentPath={props.match.path}  >
      <div className={isPC?"h-inner":'f16'}>
        <div style={{paddingBottom:100,margin:'20px 10px'}}>
          <p>
            <strong>总则</strong><br /><br />
用户在接受“D9”（d9.aiphoto8.cn）服务之前，请务必仔细阅读本声明，用户直接或间接使用“D9”服务和数据的行为，都将被视作已无条件接受本声明所涉全部内容；若用户对本声明的任何条款有异议，请停止使用“D9”所提供的全部服务。<br /><br />
            <strong>第一条 用户承诺</strong><br /><br />
用户以各种方式使用“D9”服务和数据（包括但不限于上传、发布和分享图片）的过程中，不得以任何方式利用“D9”直接或间接从事违反中国法律法规、以及社会公德的行为，且用户应当恪守下述承诺：<br />
1.上传、发布、分享的图片内容符合中国法律法规、社会公德；<br />
2.上传、发布、分享的图片不得侵害正当权利人之权利；<br />
3.不得干扰、损害和侵犯“D9”的各种合法权利与利益；<br />
4.遵守“D9”以及与之相关的网络服务的协议、指导原则、管理细则等。<br />
5.“D9”有权对违反上述承诺的内容予以删除。<br /><br />
            <strong>第二条 免责说明</strong><br /><br />
“D9”仅为用户上传、发布、分享的图片内容提供网络平台和存储空间。用户在“D9”上传、发布、分享的图片内容，仅表明其个人的立场和观点，并不代表“D9”的立场和观点。作为图片内容的发布者，需自行对所上传、发布、分享的图片内容负责，因所发表的图片内容引发的一切纠纷、损失，由该图片内容的发表者承担全部直接或间接（连带）法律责任，“D9”不承担任何法律责任。<br />
用户在“D9”上传、发布、分享的图片内容，均系用户个人行为产生，任何个人、公司或其他主体使用上述内容从事盈利性或非盈利性行为导致的侵犯他人知识产权或其他合法权益而产生的一切纠纷、损失均由该个人、公司或其他主体承担全部直接或间接（连带）法律责任，“D9”不承担任何法律责任。<br />
用户在“D9”上传、发布、分享的图片内容侵犯他人知识产权或其他合法权益的内容时或者“D9”接到相关权利人投诉、举报时，“D9”有权对侵权内容予以删除或进行其他限制措施，并保留移交相关行政机关处理的权利，“D9”不承担因此产生的一切损失或其他法律责任。<br />
个人用户在申请注册用户时，不得使用已在工商局注册的企业或品牌名称作为用户名，经查实无法提供相关证明的，“D9”有权收回帐号。“D9”的删除或其他限制措施行为系履行网络服务提供者的审查、监管义务行为，不承担相关赔偿责任。“D9”作为网络服务提供者与用户及相关权利人之间的纠纷无关。<br /><br />
            <strong>第三条 维权须知</strong><br /><br />
“D9”作为网络图片服务分享平台，对非法转载，盗版行为的发生不具备充分的监控能力。但是当版权拥有者提出侵权指控并出示充分的版权证明材料时，“D9”负有移除盗版和非法转载作品以及停止继续传播的义务。“D9”在满足前款条件下采取移除等相应措施后不为此向原发布人承担违约责任或其他法律责任，包括不承担因侵权指控不成立而给原发布人带来损害的赔偿责任。<br />
如果版权拥有者发现自己作品被侵权，请及时向“D9”提出权利通知，并将姓名、电话、身份证明、权属证明、具体链接（URL）及详细侵权情况描述发往版权举报专用通道，以便“D9”迅速做出处理。<br /><br />
            <strong>第四条 版权举报通道</strong><br /><br />
邮箱：wpkuang@jizhitech.io
</p><br />
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </div>

      </div>
    </BasePage>

  )
}


export default Term;