import React from 'react';
import { IsPC } from '../../util';
import GzhCode from '../../images/gzhcode.png'
import SpCode from '../../images/spCode.png'
const Footer = ({ fixed, currentPath }) => {
  const isPC = IsPC()
  return (
    <div className={`footer ${fixed ? 'fixed' : ''}`} style={{ background: currentPath === '/' ? '#f5f5f5' : '#f5f5f5', color: currentPath === '/' ? '#000424' : '#000424' }}>
      <div className='flex alc jc_c auto' style={{ width: isPC ? '50%' : '90%', minWidth: '300px' }}>
        <div >
          <p className={isPC?'':'center'}>
            <span className="useradjest" style={{paddingRight:'15px'}}><a href="/term.html" style={{ color: '#000424' }}> 用户协议</a></span>
            <span className="useradjest" style={{paddingLeft:'15px'}} ><a href="/contact.html" style={{ color: '#000424' }}> 联系我们</a></span>
          </p>
          <p className={isPC?'copy-right':'center copy-right'}>Copyright © 2020-2021 Jizhi. All Rights Reserved. 积致科技 版权所有</p>
          <p className={isPC?'':'center'}>
            <a href="https://beian.miit.gov.cn/" style={{ color: '#000424' }}>京ICP备2021004350号</a>
          </p>
        </div>
        <div className='flex '>
        <div className='paxs ml10'>
            <div className='center mbsm'>视频号</div>
            <img src={SpCode} style={{width:'calc(1.5rem + 60px)'}} mode="widthFix"></img>
          </div>
          <div className='paxs ml10'>
            <div className='center mbsm'>公众号</div>
            <img src={GzhCode} style={{width:'calc(1.5rem + 60px)'}} mode="widthFix"></img>
          </div>
          <div>

          </div>
        </div>
      </div>
    </div>
  )
}


export default Footer;